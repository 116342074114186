
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { client } from '@/client';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent } from 'vue';
import { FriendsData, FriendsStatus } from "@/shared/types/FriendsData";
import { ReqList } from "@/shared/adminprotocols/friends/PtlList";
export default defineComponent({
    name: "friend-index",
    setup() {
      return {
        Edit,
        Delete,
        View,
        Search,
      };
    },
    data: () => {
      return {
        roles: [] as {label:string,value:string}[],
        roleSelected:['0'] as any[],
        keywords: "",
        userid:0,
        tableData:[] as FriendsData[],
        multipleSelection: [] as any[],
        count: 0,
        cur: 1,
        username: "",
        pageSize:10
      };
    },
    created() {
      console.log(this.$route.params,"created")
      this.userid = parseInt(this.$route.params.id as string);
      this.list();
      this.roleSelected = ['0'];
      this.groupList();
    },
    watch: {
      $route(to, from) {
        console.log(to);
        if (to.name == "friend-index") {
          let { cur, keywords } = to.query;
          this.cur = +cur;
          this.keywords = keywords;
          this.list();
        }
      },
    },
    methods: {
      handleChange(){
          console.error(this.roleSelected[0]);
      },
      clearSearch() {
        this.$router.replace({
          name: "friend-index",
          query: { cur: 1, cid: 0, keywords: "" },
        });
      },
      doSearch() {
        this.$router.replace({
          name: "friend-index",
          query: { cur:1, keywords: this.keywords },
        });
        this.list();
      },
      //查询
      async list() {
        try {
            let data:ReqList = {userid:this.userid,cur:this.cur,pageSize:this.pageSize};
            if(this.keywords != ""){
                data.key = this.keywords;
            }
            if (this.userid != 0) {
                data.userid = this.userid;
            }
            if (this.roleSelected[0] != "0") {
              data.groupId = parseInt(this.roleSelected[0]);
            }
          let res = await client.callApi("friends/List",data);
          if (res.isSucc) {
              this.tableData = [...res.res.list];
            this.count = res.res.count;
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      //翻页
      handleCurrentChange(e: number) {
        this.cur = e;
        this.list();
      },
  
      toggleSelection(rows: any[]) {
        let refs:any = this.$refs.multipleTable;
        if (rows) {
          rows.forEach((row: any) => {
            if (refs) {
              refs.toggleRowSelection(row);
            }
          });
        } else {
              refs.clearSelection();
        }
      },
  
      handleSelectionChange(val: never[]) {
        this.multipleSelection = val;
      },
  
      //编辑
      toEdit(e: { id: any; }) {
        let id = e.id;
        this.$router.push({ name: "friend-edit", params: { id: id } });
      },
      goGroup(){
        this.$router.push({ name: "group-index", params: { id: this.userid } });
      },
      goFriendAdd(){
        this.$router.push({ name: "friend-add", params: { id: this.userid } });
      },
      delSome() {
          let ids = this.multipleSelection.map((item) => {
          return parseInt(item.id);
          });
          ElMessageBox.confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          })
          .then(async () => {
          let res = await client.callApi("friends/Del",{ids:ids});
          if (res.isSucc) {
              ElMessage({
                  message: "删除成功 ^_^",
                  type: "success",
              });
              this.list();
              }
          })
          .catch(() => {
              ElMessage({
              type: "info",
              message: "已取消删除",
              });
          });
      },
    //查询栏目
    async groupList() {
        this.roles = [{label:"全部",value:"0"}];
        let res = await client.callApi("group/Index",{userid:this.userid});
        if (res.isSucc) {
          let data = res.res.list;
          for (const item of data) {
            this.roles.push({
              label:item.groupName,
              value:item.id.toString()
            })
          }
        }
    },
        handleSizeChange(val: { pageSize: number; }){
          this.pageSize = val.pageSize;
          this.list();
        },
        formatterGroup(row:FriendsData) {
            return row.group?row.group.groupName:"未分组";
        },
        formatterStatus(row:FriendsData) {
            let str = "后台修改";
            switch (row.status) {
                case FriendsStatus.applying:
                    str = "黑名单";
                    break;
                case FriendsStatus.agree:
                    str = "好友";
                    break;
                case FriendsStatus.rejected:
                    str = "亲密好友";
                    break;
            }
            return str;
        },
  
      //删除
        async handleDel(e: { id: any; }) {
            let id = e.id;
            try {
            ElMessageBox.confirm("这么疯狂，要删数据？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                let res = await client.callApi("friends/Del",{ids:[id]});
                // let res = await del(id);
                if (res.isSucc) {
                    ElMessage({
                    message: "删除成功 ^_^",
                    type: "success",
                    });
                    this.list();
                }
                })
                .catch(() => {
                ElMessage({
                    type: "info",
                    message: "已取消删除",
                });
                });
            } catch (error) {
            console.log(error);
            }
        },
        handleChangeSort(e:{prop:any,order:'descending'|"ascending"|null}){
            console.error(e);
            this.list();
        }
    },
  });
  