import { UserData } from "./UserData";

/**
 * 好友类别
 */
export enum FriendsStatus {
    /**
     * 黑名单
     */
    applying = '1',
    /**
     * 我的好友
     */
    agree = '2',
    /**
     * 未知好友
     */
    rejected  = '3'
}
export interface FriendsData{

    id: number
    /**
     * 用户ID，外键关联到用户表的ID字段
     */
    userId: number
    
    user?: UserData;

    /**
     * 好友ID，外键关联到用户表的ID字段
     */
    friendId: number
    
    friend?: UserData;
    /**
     * 好友所在的分组ID,外键关联到分组表的ID字段,可以为NULL（表示未分组）
     */
    groupId?: number
    
    group?: FriendGroupsData;
    /**
     * 好友关系状态（1：申请中,2：已成为好友,3：已拒绝）
     */
    status:FriendsStatus
    /**
     * 备注名称
     */
    remark: string
    /**
     * 创建时间
     */
    createdAt:Date
    /**
     * 修改时间
     */
    updatedAt:Date
}
export interface FriendGroupsData{
    id: number
    /**
     * 用户ID，外键关联到用户表的ID字段
     */
    userId: number
    /**
     * 分组名称
     */
    
    groupName: string
    /**
     * 好友列表
     */
    friends?:FriendsData[]
}